import React, { useContext, useEffect } from 'react';

import { Button } from 'reactstrap';
import ServerSmallSvg from '../../../../images/cloudPilot/server-small.svg';
import FirewallSmallSvg from '../../../../images/cloudPilot/firewall-small.svg';
import LoadBalancerSmallSvg from '../../../../images/cloudPilot/load-balancer-small.svg';
import axios from '../../../../axiosInstance';
import { useParams } from 'react-router-dom';
import { ArchitectureContext } from '../context/ArchitectureContext';
import { AuthContext } from '../../../contexts/AuthContext';

const AddComponents = ({ component, closePopover }) => {
  const [allowedEntities, setAllowedEntities] = React.useState([]);
  const [entityCount, setEntityCount] = React.useState({});
  const [disableAddButton, setDisableAddButton] = React.useState(false);
  const { id } = useParams();
  const { getTemplateAllowedChildren, fetchDetails } = useContext(ArchitectureContext);
  const { tenant } = useContext(AuthContext);

  useEffect(() => {
    setAllowedEntities(getTemplateAllowedChildren(component.type));

    const entityCountList = {};

    if (allowedEntities.length > 1) {
      allowedEntities.forEach(connection => {
        entityCountList[connection] = 0;
      });
    } else {
      entityCountList[allowedEntities[0]] = 1;
    }
    setEntityCount(entityCountList);
  }, [allowedEntities]);

  const entityMap = {
    internet: {
      icon: ServerSmallSvg,
      name: 'Internet',
    },
    device: {
      icon: ServerSmallSvg,
      name: 'Server',
    },
    firewall: {
      icon: FirewallSmallSvg,
      name: 'Firewall',
    },
    loadBalancer: {
      icon: LoadBalancerSmallSvg,
      name: 'Load Balancer',
    },
    wan: {
      icon: ServerSmallSvg,
      name: 'WAN',
    },
    lan: {
      icon: ServerSmallSvg,
      name: 'LAN',
    },
  };

  const handleAddEntity = connection => {
    setEntityCount(prevCount => ({
      ...prevCount,
      [connection]: (prevCount[connection] || 0) + 1,
    }));
  };

  const handleRemoveEntity = entity => {
    setEntityCount(prevCount => ({
      ...prevCount,
      [entity]: (prevCount[entity] || 0) - 1,
    }));
  };

  const handleSubmitAdding = () => {
    setDisableAddButton(true);
    const payload = {
      sourceNodeId: component.id,
      componentListItems: entityCount,
    };

    axios
      .post(`/api/user/${tenant}/architectures/${id}`, payload)
      .then(res => {
        fetchDetails();
        setDisableAddButton(false);
        closePopover();
      })
      .catch(e => {
        console.warn(e.message);
        setDisableAddButton(false);
      });
  };

  return (
    <>
      {allowedEntities.map((entity, index) => (
        <div className="d-flex justify-content-between align-items-center width-px-300 mb-3 font-size-lg" key={index}>
          <img className="width-px-50" src={entityMap[entity].icon} alt="icon" />
          <span className="bold w-100 text-left ml-3">{entityMap[entity].name}</span>
          <div className="d-flex align-items-center">
            <button className="grayCircleButton text-blue-dark" onClick={() => handleRemoveEntity(entity)}>
              -
            </button>
            <span className="d-flex justify-content-center mx-3 text-blue-dark width-px-10 bold">
              {entityCount[entity]}
            </span>
            <button className="grayCircleButton text-blue-dark" onClick={() => handleAddEntity(entity)}>
              +
            </button>
          </div>
        </div>
      ))}
      <Button className="btn-violet w-100" disabled={disableAddButton} onClick={handleSubmitAdding}>
        <span className="font-size-lg">+ Add</span>
      </Button>
    </>
  );
};

export default AddComponents;
