import React, { useContext, useEffect, useState } from 'react';
import { FormFeedback, FormGroup, Input, Label, Button } from 'reactstrap';
import Select from 'react-select';
import clsx from 'clsx';

import StepCard from '../../../../../../AddDevicePage/StepCard/StepCard';
import axios from '../../../../../../../axiosInstance';
import Skeleton from 'react-loading-skeleton';
import { AuthContext } from '../../../../../../contexts/AuthContext';

const initialNewScript = {
  name: '',
  code: '',
  type: 'bash',
};

const Scripts = ({ className, changeDeviceEntityHandler, deviceEntity }) => {
  const [radioValue, setRadioValue] = useState('no_scripts');
  const [newScript, setNewScript] = useState(initialNewScript);
  const [isFetching, setIsFetching] = useState(true);
  const [scripts, setScripts] = useState([]);
  const [isExistingSet, setIsExistingSet] = useState(false);
  const [isFoldable, setIsFoldable] = useState(true);

  const { tenant } = useContext(AuthContext);

  useEffect(() => {
    if (deviceEntity.scriptId && !isExistingSet) {
      setRadioValue('select_script');
      setIsFoldable(false);
      setIsExistingSet(true);
    }
  }, [isExistingSet]);

  useEffect(() => {
    fetchScripts();
  }, []);

  const fetchScripts = () => {
    setIsFetching(true);
    axios
      .get('api/user/scripts', {
        params: {
          page: 1,
          perPage: 1000,
          tenantIds: [tenant],
        },
      })
      .then(res => {
        if (res?.data?.data) {
          setScripts(res.data.data.map(el => ({ value: el.id, label: el.name })));
        }
        setIsFetching(false);
      })
      .catch(e => {
        console.warn(e.message);
        setIsFetching(false);
      });
  };

  const addScript = script => {
    axios.post(`/api/user/${tenant}/templates/script/create`, script).then(res => {
      if (res?.data) {
        setScripts(prevState => [...prevState, { value: res.data.id, label: res.data.name }]);
        setRadioValue('select_script');
        changeDeviceEntityHandler('scriptId', res.data.id);
      }
    });
  };

  return (
    <StepCard className={clsx(className)} title="Run script after setup (optional)" foldable={isFoldable}>
      <div className="d-flex flex-column">
        <FormGroup check inline>
          <input
            data-cy="add-device-no-scripts"
            type="radio"
            id="no_scripts"
            name="scripts"
            value="no_scripts"
            onChange={event => {
              setRadioValue(event.target.value);
              changeDeviceEntityHandler('scriptId', null);
            }}
            checked={radioValue === 'no_scripts'}
          />
          <label htmlFor="no_scripts" className="m-0 ml-2">
            Don't run any scripts
          </label>
        </FormGroup>
        <FormGroup check inline>
          <input
            data-cy="add-device-select-existing"
            type="radio"
            id="select_script"
            name="scripts"
            value="select_script"
            onChange={event => setRadioValue(event.target.value)}
            checked={radioValue === 'select_script'}
          />
          <label htmlFor="select_script" className="m-0 ml-2">
            Select existing
          </label>
        </FormGroup>
        {radioValue === 'select_script' &&
          (!isFetching ? (
            <>
              <FormGroup check inline>
                <Select
                  data-cy="add-device-select-scripts"
                  className="w-100"
                  id="script_select"
                  value={
                    deviceEntity.scriptId
                      ? scripts.find(el => el.value === deviceEntity.scriptId)
                      : { value: '', label: 'Select script' }
                  }
                  onChange={val => changeDeviceEntityHandler('scriptId', val.value)}
                  options={scripts}
                />
              </FormGroup>
              <FormFeedback className="d-block input__danger-message">
                {deviceEntity.errors.first('scriptId')}
              </FormFeedback>
            </>
          ) : (
            <Skeleton height={30} width={320} />
          ))}
        <FormGroup check inline>
          <input
            data-cy="add-device-new-script"
            type="radio"
            id="new_script"
            name="scripts"
            value="new_script"
            checked={radioValue === 'new_script'}
            onChange={event => setRadioValue(event.target.value)}
          />
          <label htmlFor="new_script" className="m-0 ml-2">
            New Script
          </label>
        </FormGroup>
        {radioValue === 'new_script' && (
          <div>
            <FormGroup>
              <Label for="name">Script name</Label>
              <Input
                data-cy="add-device-script-name"
                id="name"
                name="name"
                value={newScript.name}
                onChange={e => setNewScript({ ...newScript, name: e.target.value })}
                required
              />
              <FormFeedback>This field is required</FormFeedback>
            </FormGroup>
            <FormGroup check>
              <Input
                data-cy="add-device-script-powershell"
                type="radio"
                id="powershell"
                name="type"
                value="powershell"
                checked={newScript.type === 'powershell'}
                onChange={e => setNewScript({ ...newScript, type: e.target.value })}
                required
              />
              <Label check for="powershell">
                Windows Powershell Script
              </Label>
            </FormGroup>
            <FormGroup check>
              <Input
                data-cy="add-device-script-bash"
                type="radio"
                id="bash"
                name="type"
                value="bash"
                checked={newScript.type === 'bash'}
                onChange={e => setNewScript({ ...newScript, type: e.target.value })}
                required
              />
              <Label check for="bash">
                Linux /bin/bash Script
              </Label>
            </FormGroup>
            <FormGroup>
              <Label for="body">Script content</Label>
              <Input
                data-cy="add-device-script-code"
                type="textarea"
                id="code"
                name="code"
                value={newScript.code}
                onChange={e => setNewScript({ ...newScript, code: e.target.value })}
                required
              />
              <FormFeedback>This field is required</FormFeedback>
            </FormGroup>
            <Button
              data-cy="add-device-script-submit-button"
              color="primary"
              onClick={e => {
                if (newScript.code !== '' && newScript.name !== '' && newScript.type !== undefined) {
                  addScript(newScript, () => {
                    setRadioValue('select_script');
                    setNewScript(initialNewScript);
                  });
                  e.preventDefault();
                  e.stopPropagation();
                  e.cancelBubble = true;
                }
              }}
            >
              Save script to library
            </Button>
          </div>
        )}
      </div>
    </StepCard>
  );
};

export default Scripts;
