import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { ArchitectureContext } from '../../context/ArchitectureContext';
import { Button } from 'reactstrap';
import Total from './Total';
import LogsSvg from './svg/LogsSvg';
import CostSvg from './svg/CostSvg';
import Logs from './Logs';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../../../contexts/AuthContext';
import axios from '../../../../../axiosInstance';

const SummaryBlock = () => {
  const [clickedButton, setClickedButton] = useState('logs');
  const [notReadyStatusCount, setNotReadyStatusCount] = useState(0);

  const { architectureDetails, isSummaryOpen, setIsSummaryOpen, setReviewModalOpen } = useContext(ArchitectureContext);
  const { id } = useParams();
  const { tenant } = useContext(AuthContext);

  useEffect(() => {
    if (architectureDetails) {
      const notReadyStatusCount = architectureDetails.components.filter(item => item.status === 'not ready').length;
      setNotReadyStatusCount(notReadyStatusCount);
    }
  }, [architectureDetails]);

  const deployHandler = () => {
    axios.post(`/api/user/${tenant}/architectures/${id}/review`).catch(error => {
      console.error(error);
    });
    setReviewModalOpen(true);
  };

  return (
    <div className={clsx('p-3 summary-form', isSummaryOpen && 'summary-form-open')}>
      <div className="d-flex align-items-center px-4">
        <h2 className="bold mb-0">Summary & deploy</h2>
        <Button
          className="btn btn-white btn-sm ml-2"
          onClick={() => {
            setIsSummaryOpen(false);
          }}
        >
          Hide
        </Button>
      </div>
      <Total />
      <Button className="btn-violet w-100 mt-3" disabled={notReadyStatusCount > 0} onClick={deployHandler}>
        Review & Deploy
      </Button>
      {notReadyStatusCount > 0 && (
        <div className="alert alert-warning mt-2 mb-0" role="alert">
          <div className="d-flex align-items-center">
            <i className="fas fa-info-circle mr-3" style={{ fontSize: '1.5em' }} />
            <p className="mb-0">
              {notReadyStatusCount} node{notReadyStatusCount === 1 ? ' is' : 's are'} not prepared for deployment
            </p>
          </div>
        </div>
      )}
      <hr />
      <div className="d-flex grid-gap-12">
        <Button
          className={clsx(
            'btn-nostyle d-flex align-items-center w-25 border rounded p-2 align-items-center',
            clickedButton === 'logs' ? 'border-width-2 border-blue-dark text-blue-dark' : 'border-width-1 border-dark'
          )}
          onClick={() => setClickedButton('logs')}
        >
          <LogsSvg color={clickedButton === 'logs' ? 'blue' : 'dark'} />
          <span className="ml-2">Change Log</span>
        </Button>
        <Button
          className={clsx(
            'btn-nostyle d-flex align-items-center w-25 border border-width-1 rounded p-2 align-items-center',
            clickedButton === 'cost' ? 'border-width-2 border-blue-dark text-blue-dark' : 'border-width-1 border-dark'
          )}
          onClick={() => setClickedButton('cost')}
        >
          <CostSvg color={clickedButton === 'cost' ? 'blue' : 'dark'} />
          <span className="ml-2">Costs</span>
        </Button>
      </div>
      {clickedButton === 'logs' && <Logs />}
    </div>
  );
};

export default SummaryBlock;
