import React, { useContext, useEffect, useState } from 'react';
import { Button, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import clsx from 'clsx';
import { ArchitectureContext } from '../../context/ArchitectureContext';
import axios from '../../../../../axiosInstance';
import { AuthContext } from '../../../../contexts/AuthContext';
import { useParams } from 'react-router-dom';
import { handleDateObjectConversion } from '../../../../../utils/timeConversion';
import { EchoContext } from '../../../../contexts/EchoContext';

const ModalReviewAndDeploy = () => {
  const [output, setOutput] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const { architectureDetails, isReviewModalOpen, setReviewModalOpen } = useContext(ArchitectureContext);
  const { tenant, user, isAuth } = useContext(AuthContext);
  const { echo } = useContext(EchoContext);
  const { id } = useParams();

  const toggleModal = () => {
    if (isReviewModalOpen) {
      setReviewModalOpen(false);
      const timeout = setTimeout(() => {
        setOutput('');
      }, 1000);

      return () => clearTimeout(timeout);
    } else {
      setReviewModalOpen(true);
    }
  };

  const getOutput = () => {
    axios
      .get(`/api/user/${tenant}/architectures/${id}/command-output?command=terraform-plan`)
      .then(res => {
        setOutput(res.data);
        setIsLoaded(true);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const deployHandler = () => {
    axios.get(`/api/user/${tenant}/architectures/${id}/deploy`).catch(error => {
      console.error(error);
    });
  };

  useEffect(() => {
    if (echo) {
      echo.private(`architecture.${id}`).listen('.architectureOutput', res => {
        if (res.message === 'Terraform plan output is ready') {
          getOutput();
        }
      });
    }
  }, [echo]);

  const formattedOutput = output => {
    const formatOutput = text => {
      const parts = text.split(/( \+ | - | ~ |Error:)/g);
      return parts.map((part, index) => {
        switch (part) {
          case ' + ':
            return (
              <span key={index} style={{ color: 'green', fontWeight: 'bold' }}>
                {part}
              </span>
            );
          case ' - ':
            return (
              <span key={index} style={{ color: 'red', fontWeight: 'bold' }}>
                {part}
              </span>
            );
          case ' ~ ':
            return (
              <span key={index} style={{ color: 'orange', fontWeight: 'bold' }}>
                {part}
              </span>
            );
          case 'Error:':
            return (
              <span key={index} style={{ color: 'red', fontWeight: 'bold' }}>
                {part}
              </span>
            );
          default:
            return part;
        }
      });
    };

    return <pre>{formatOutput(output)}</pre>;
  };

  return (
    <Modal isOpen={isReviewModalOpen} size="lg">
      <ModalHeader toggle={toggleModal}>{architectureDetails?.name} review</ModalHeader>
      <ModalBody>
        {output === '' ? (
          <div className="d-flex flex-column align-items-center">
            <div className="mb-2">Waiting for the output...</div>
            <div className="spinner-border spinner-border-lg text-primary mb-1 mx-2 my-4" role="status" />
            <div className="mt-2">
              <Button className="btn btn-success text-white" onClick={() => getOutput()}>
                Reload
              </Button>
            </div>
          </div>
        ) : (
          <div className="mx-4">{formattedOutput(output)}</div>
        )}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-end">
        <Button data-cy="architecture-modal-button-submit" type="submit" color="secondary" onClick={deployHandler}>
          Deploy
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalReviewAndDeploy;
