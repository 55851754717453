import React, { useContext, useEffect, useState } from 'react';
import SelectedEntity from './formComponents/SelectedEntity';
import NamingAndOs from './formComponents/Device/NamingAndOs';
import Hardware from './formComponents/Device/Hardware';
import Security from './formComponents/Device/Security';
import Monitoring from './formComponents/Device/Monitoring';
import Backups from './formComponents/Device/Backups';
import { Button } from 'reactstrap';
import { AuthContext } from '../../../../contexts/AuthContext';
import { useParams } from 'react-router-dom';
import { ArchitectureContext } from '../../context/ArchitectureContext';
import axios from '../../../../../axiosInstance';
import Connections from './formComponents/Connections';
import MulticloudReplication from './formComponents/Device/MulticloudReplication';
import Scripts from '../forms/formComponents/Device/Scripts';
import SshKeys from './formComponents/Device/SshKeys';
import Form from '../../../../../utils/form/form';
import cloneObject from '../../../../../utils/cloneObject';

const ServerForm = () => {
  const [data, setData] = useState(
    new Form({
      name: '',
      hostname: '',
      templateId: null,
      memory: 2,
      cpuCoreCount: 2,
      diskSize: 5,
      swapDiskSize: null,
      password: '',
      passwordConfirmation: '',
      scriptId: null,
      sshKeyId: null,
      enableBackup: false,
      backupJobId: null,
      enableReplication: false,
      replicationId: null,
      enableMonitoring: false,
    })
  );
  const [isDeviceEntityReady, setIsDeviceEntityReady] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [templateInfo, setTemplateInfo] = useState({});

  const { entityData, setIsEntityChanged, fetchDetails, setIsEntityOpen } = useContext(ArchitectureContext);
  const { tenant } = useContext(AuthContext);
  const { id } = useParams();

  useEffect(() => {
    if (entityData) {
      const cloneData = cloneObject(data);
      cloneData.name = entityData.data.name;
      cloneData.hostname = entityData.data.hostname;
      cloneData.templateId = entityData.data.templateId;
      cloneData.memory = entityData.data.memory;
      cloneData.cpuCoreCount = entityData.data.cpuCoreCount;
      cloneData.diskSize = entityData.data.diskSize;
      cloneData.swapDiskSize = entityData.data.swapDiskSize;
      cloneData.password = entityData.data.password || '';
      cloneData.passwordConfirmation = entityData.data.password || '';
      cloneData.scriptId = entityData.data.scriptId;
      cloneData.sshKeyId = entityData.data.sshKeyId;
      cloneData.enableBackup = entityData.data.enableBackup;
      cloneData.backupJobId = entityData.data.backupJobId;
      cloneData.enableReplication = entityData.data.enableReplication;
      cloneData.replicationId = entityData.data.replicationId;
      cloneData.enableMonitoring = entityData.data.enableMonitoring;

      cloneData.clearAllResults();

      setData(cloneData);
      setIsDeviceEntityReady(true);
    }
  }, [entityData]);

  const changeDeviceEntityHandler = (key, value) => {
    const newData = cloneObject(data);
    newData[key] = value;
    newData.errors.clear(key);
    setData(newData);
  };

  const changePasswordHandler = newPassword => {
    const newData = cloneObject(data);
    newData.password = newPassword;
    newData.passwordConfirmation = newPassword;
    newData.errors.clear('password');
    newData.errors.clear('passwordConfirmation');
    setData(newData);
  };

  const updateScriptHandler = async () => {
    setIsUpdating(true);

    try {
      await data.submitUsing(async () => {
        await axios.put(`/api/user/${tenant}/architectures/${id}/node/${entityData.id}`, {
          nodeType: 'device',
          ...data.data(),
        });
        setIsUpdating(false);
        setIsEntityChanged(false);
        setIsEntityOpen(false);
        fetchDetails();
      }, false);
    } catch (e) {
      // for rerender component
      setData(cloneObject(data));

      setIsUpdating(false);
      console.warn(e.message);
    }
  };

  return (
    <div className="d-flex flex-column full-height">
      {isDeviceEntityReady ? (
        <div>
          <SelectedEntity />
          <Connections />
          <NamingAndOs
            deviceEntity={data}
            changeDeviceEntityHandler={changeDeviceEntityHandler}
            setTemplateInfo={setTemplateInfo}
          />
          <Hardware
            deviceEntity={data}
            changeDeviceEntityHandler={changeDeviceEntityHandler}
            templateInfo={templateInfo}
          />
          <Security
            deviceEntity={data}
            changeDeviceEntityHandler={changeDeviceEntityHandler}
            changePasswordHandler={changePasswordHandler}
          />
          <Backups deviceEntity={data} changeDeviceEntityHandler={changeDeviceEntityHandler} />
          <MulticloudReplication deviceEntity={data} changeDeviceEntityHandler={changeDeviceEntityHandler} />
          {templateInfo.template &&
            (templateInfo.template.type === 'Windows' || templateInfo.template.type === 'Linux') && (
              <Monitoring deviceEntity={data} changeDeviceEntityHandler={changeDeviceEntityHandler} />
            )}
          <SshKeys className="mt-3" deviceEntity={data} changeDeviceEntityHandler={changeDeviceEntityHandler} />
          <Scripts className="mt-3" deviceEntity={data} changeDeviceEntityHandler={changeDeviceEntityHandler} />
        </div>
      ) : (
        <span>Loading...</span>
      )}
      <div className="architecture-sticky-button-wrapper mt-3">
        <Button className="btn-violet w-100" onClick={() => updateScriptHandler()} disabled={isUpdating}>
          {isUpdating && (
            <span className="spinner-border spinner-border-sm mb-1 mr-2" role="status" aria-hidden="true" />
          )}
          Update script
        </Button>
      </div>
    </div>
  );
};

export default ServerForm;
