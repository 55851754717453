import React, { useState, Fragment, useEffect } from 'react';
import { Button, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import clsx from 'clsx';

import StepCard from '../../../../../../AddDevicePage/StepCard/StepCard';
import { addOptions } from '../../../../../../AddOrganizationPage/initData';
import Select from '../../../../../../UI/SelectRequired';
import axios from '../../../../../../../axiosInstance';

const SshKeys = ({ className, deviceEntity, changeDeviceEntityHandler }) => {
  const [keyType, setKeyType] = useState('noKey');
  const [formVal, setFormValues] = useState({
    name: '',
    ssh_key: '',
    id: undefined,
  });
  const [sshKeys, setSshKeys] = useState([]);
  const [isExistingSet, setIsExistingSet] = useState(false);
  const [isFoldable, setIsFoldable] = useState(true);

  const fetchSshKeys = () => {
    axios
      .get('/api/user/sshKeys')
      .then(res => setSshKeys(res.data))
      .catch(error => console.error('Error fetching SSH keys:', error));
  };

  useEffect(() => {
    fetchSshKeys();
  }, []);

  useEffect(() => {
    if (deviceEntity.sshKeyId && !isExistingSet) {
      setKeyType('existing');
      setIsExistingSet(true);
      setIsFoldable(false);
    }
  }, [deviceEntity, isExistingSet]);

  const inputChangeHandler = e => {
    setFormValues({
      ...formVal,
      [e.target.name]: e.target.value,
    });
  };

  const toInitialstate = () => {
    setFormValues({
      name: '',
      ssh_key: '',
      id: undefined,
    });
  };

  const addSshKeyHandler = formVal => {
    axios
      .post('api/user/vmlist/ssh/add', formVal)
      .then(res => {
        if (res && res.data) {
          const { id, name, created_at, fingerprint } = res.data;
          setSshKeys(prevState => [...prevState, { id, name, created_at, fingerprint }]);
          toInitialstate();
          setKeyType('existing');
          changeDeviceEntityHandler('sshKeyId', id);
        }
      })
      .catch(e => {
        console.warn(e.message);
      });
  };

  return (
    <StepCard className={clsx(className)} title="Add ssh key (Optional)" foldable={isFoldable}>
      <div>
        <div className="d-flex flex-column">
          <FormGroup check inline>
            <input
              data-cy="add-device-ssh-no-key"
              type="radio"
              name="type"
              id="add-device-ssh-no-key"
              value="noKey"
              checked={keyType === 'noKey'}
              required
              onChange={e => {
                setKeyType(e.target.value);
                toInitialstate();
                changeDeviceEntityHandler('sshKeyId', null);
              }}
            />
            <label htmlFor="add-device-ssh-no-key" className="m-0 ml-2">
              No key
            </label>
          </FormGroup>
          {addOptions.map(({ value, label }) => (
            <Fragment key={value}>
              <FormGroup check inline>
                <input
                  data-cy={`add-device-ssh-${label}`}
                  type="radio"
                  name="type"
                  id={'type' + value}
                  value={value}
                  checked={keyType === value}
                  required
                  onChange={e => {
                    setKeyType(e.target.value);
                    toInitialstate();
                  }}
                />
                <label htmlFor={'type' + value} className="m-0 ml-2">
                  {label}
                </label>
              </FormGroup>
              {keyType === 'existing' && value === 'existing' && (
                <>
                  <Select
                    dataCy="add-device-ssh-select"
                    required
                    options={sshKeys.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    }))}
                    value={{
                      value: deviceEntity.sshKeyId,
                      label: sshKeys.find(el => el.id === deviceEntity.sshKeyId)
                        ? sshKeys.find(el => el.id === deviceEntity.sshKeyId).name
                        : undefined,
                    }}
                    onChange={({ value }) => {
                      changeDeviceEntityHandler('sshKeyId', value);
                    }}
                  />
                  <FormFeedback className="d-block input__danger-message">
                    {deviceEntity.errors.first('sshKeyId')}
                  </FormFeedback>
                </>
              )}
            </Fragment>
          ))}
        </div>
        {keyType === 'new' && (
          <>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                data-cy="add-device-ssh-new-name"
                type="text"
                name="name"
                id="name"
                value={formVal.name}
                onChange={inputChangeHandler}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="sshkey">Key</Label>
              <Input
                data-cy="add-device-ssh-new-key"
                type="textarea"
                name="ssh_key"
                id="sshkey"
                value={formVal.ssh_key}
                onChange={inputChangeHandler}
                required
              />
            </FormGroup>
            <Button
              data-cy="add-device-ssh-new-save-button"
              color="primary"
              onClick={() =>
                addSshKeyHandler(formVal, ({ id }) => {
                  setKeyType('existing');
                })
              }
            >
              Save
            </Button>
          </>
        )}
      </div>
    </StepCard>
  );
};

export default SshKeys;
