import React, { useEffect, useState } from 'react';
import { Button, FormFeedback, Input } from 'reactstrap';
import generator from 'generate-password';
import PasswordField from '../../../../../../UI/NewPasswordField';

const Security = ({ deviceEntity, changeDeviceEntityHandler, changePasswordHandler }) => {
  const [visiblePassword, setVisiblePassword] = useState(false);

  const generatePasswordHandler = () => {
    const password = generator.generate({
      length: 10,
      numbers: true,
      symbols: true,
      strict: true,
      exclude: '$[]{}<>~^`£|/\\',
    });

    setVisiblePassword(true);

    changePasswordHandler(password);
  };

  // console.log(deviceEntity);
  //
  // useEffect(() => {
  //   if (deviceEntity.password === null) {
  //     console.log(1111);
  //     changePasswordHandler('');
  //   }
  // }, []);

  return (
    <div className="architecture-form-block w-100 rounded bg-white mt-4">
      <div className="bg-light-blue">
        <div className="architecture-form-block-header p-3">
          <p className="font-size-lg bold mb-0">Security</p>
        </div>
        <div className="pb-3 px-3">
          <div className="d-flex">
            <div className="w-50 mr-1">
              <PasswordField
                dataCy="add-edit-user-password"
                name="password"
                passwordClassname={deviceEntity?.errors && deviceEntity.errors.has('password') && 'is-invalid'}
                validationMessage={deviceEntity?.errors?.first ? deviceEntity.errors.first('password') : ''}
                value={deviceEntity.password}
                handleInputChange={e => changeDeviceEntityHandler('password', e.value)}
                label="Password"
                visiblePassword={visiblePassword}
              />
            </div>
            <div className="w-50 ml-1">
              <PasswordField
                dataCy="add-edit-user-password-confirm"
                name="password_confirmation"
                passwordClassname={
                  deviceEntity?.errors && deviceEntity.errors.has('passwordConfirmation') && 'is-invalid'
                }
                validationMessage={deviceEntity?.errors?.first ? deviceEntity.errors.first('passwordConfirmation') : ''}
                value={deviceEntity.passwordConfirmation}
                handleInputChange={e => changeDeviceEntityHandler('passwordConfirmation', e.value)}
                label="Password Confirmation"
                visiblePassword={visiblePassword}
              />
            </div>
          </div>
          <Button
            data-cy="add-edit-user-gen-password"
            type="button"
            color="secondary"
            className="btn btn-sm mt-2"
            onClick={generatePasswordHandler}
          >
            Generate password
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Security;
