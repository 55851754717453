import React, { useEffect, useState } from 'react';
import ReplicationIcon from '../../../../../../../images/cloudPilot/replication-icon.svg';
import axios from '../../../../../../../axiosInstance';
import { cloudId } from '../../../../constants';
import Select from 'react-select';
import { FormFeedback } from 'reactstrap';

const MulticloudReplication = ({ deviceEntity, changeDeviceEntityHandler }) => {
  const [replicationJobs, setReplicationJobs] = useState([]);
  const fetchBackupJobs = () => {
    axios
      .get('/api/user/replicationJob/list/' + cloudId)
      .then(res => setReplicationJobs(res.data))
      .catch(e => console.warn(e.message));
  };

  useEffect(() => {
    fetchBackupJobs();
  }, []);

  return (
    <div className="architecture-form-block w-100 rounded bg-white mt-4 p-3">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div className="d-flex align-items-center">
          <img className="width-px-60" src={ReplicationIcon} alt="replicationIcon-icon" />
          <div className="width-px-150 ml-3">
            {/*allowManageVirtualMachineMonitoring and other*/}
            <Select
              className="w-100"
              options={
                replicationJobs &&
                replicationJobs.map(replicationJob => ({
                  key: replicationJob.id,
                  label: replicationJob.job_name,
                  value: replicationJob.id,
                }))
              }
              onChange={e => {
                changeDeviceEntityHandler('replicationId', e.value);
              }}
              value={
                replicationJobs?.find(replicationJob => replicationJob.id === deviceEntity.replicationId)
                  ? {
                      label: replicationJobs.find(replicationJob => replicationJob.id === deviceEntity.replicationId)
                        .job_name,
                      value: deviceEntity.replicationId,
                    }
                  : null
              }
              isDisabled={!deviceEntity.enableReplication}
            />
            <FormFeedback className="d-block input__danger-message">
              {deviceEntity.errors.first('replicationId')}
            </FormFeedback>
          </div>
        </div>
        <div className="onoffswitch">
          <input
            type="checkbox"
            className="onoffswitch-checkbox"
            id="enableReplication"
            name="enable-replication"
            checked={deviceEntity.enableReplication}
            onChange={e => changeDeviceEntityHandler('enableReplication', !deviceEntity.enableReplication)}
            data-cy="enable-replication-checkbox"
          />
          <label className="onoffswitch-label mb-0" htmlFor="enableReplication" data-cy="enable-replication-label">
            <span className="onoffswitch-inner" />
            <span className="onoffswitch-switch" />
          </label>
        </div>
      </div>
      <div className="d-flex align-content-center">
        <h2 className="bold">Multicloud replication</h2>
        <p className="mb-0 ml-2 text-gray">*additional costs apply</p>
      </div>
      <p className="mb-0">
        This functionality creates a replica of your device on another cloud with a pre-selected frequency (RTO) that
        can be changed at any time.
      </p>
      <FormFeedback className="d-block input__danger-message">
        {deviceEntity.errors.first('enableReplication')}
      </FormFeedback>
    </div>
  );
};

export default MulticloudReplication;
