import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  ReactFlow,
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  Controls,
  useReactFlow,
  ConnectionLineType,
  MiniMap,
} from 'reactflow';
import dagre from 'dagre';

import 'reactflow/dist/style.css';

import GraphNode from './GraphNode';
import { ArchitectureContext } from '../context/ArchitectureContext';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import axios from '../../../../axiosInstance';
import { AuthContext } from '../../../contexts/AuthContext';

const dagreGraph = new dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));

const nodeWidth = 184;
const nodeHeight = 184;

const getLayoutedElements = (components, componentLinks, direction = 'TB') => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });

  components.forEach(node => {
    dagreGraph.setNode(node.id.toString(), { width: nodeWidth, height: nodeHeight });
  });
  const edgeType = 'step';

  const edges = componentLinks.map(x => ({
    id: x.id.toString(),
    source: x.fromComponentId.toString(),
    target: x.toComponentId.toString(),
    type: edgeType,
    animated: true,
  }));

  edges.forEach(edge => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  const newNodes = components.map(component => {
    const nodeWithPosition = dagreGraph.node(component.id);

    const position = component.position || {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return {
      id: component.id.toString(),
      data: component,
      type: 'graphNode',
      targetPosition: isHorizontal ? 'left' : 'top',
      sourcePosition: isHorizontal ? 'right' : 'bottom',
      position,
    };
  });

  return { nodes: newNodes, edges };
};

const nodeTypes = {
  graphNode: GraphNode,
};

const ArchitectureGraph = () => {
  const [defaultWidthPosition, setDefaultWidthPosition] = useState(200);
  const [nodes, setNodes, onNodesChange] = useNodesState();
  const [edges, setEdges, onEdgesChange] = useEdgesState();
  const { isEntityOpen, nodeButtonGroupId, architectureDetails, handleCloseEntity } = useContext(ArchitectureContext);

  const { tenant } = useContext(AuthContext);
  const { id } = useParams();

  const { screenToFlowPosition, getViewport, setViewport, getNodes } = useReactFlow();

  useEffect(() => {
    if (!architectureDetails) {
      setNodes([]);
      setEdges([]);
      return;
    }

    const { nodes, edges } = getLayoutedElements(architectureDetails.components, architectureDetails.componentLinks);

    setNodes(nodes);
    setEdges(edges);

    const myNodes = getNodes();

    const minX = Math.min(...myNodes.map(node => node.position.x));
    const maxX = Math.max(...myNodes.map(node => node.position.x + (node.width || 0)));

    setDefaultWidthPosition((maxX - minX) / 200);
  }, [architectureDetails]);

  const handleNodeDragStop = (event, node) => {
    axios
      .post(`/api/user/${tenant}/architectures/${id}/node/${node.id}/set-node-position`, {
        position: JSON.stringify(node.position),
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <div className={clsx('architecture-content', isEntityOpen ? 'w-75' : 'w-100')}>
      <div className="layout w-100">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onPaneClick={handleCloseEntity}
          defaultViewport={{ x: 200, y: defaultWidthPosition, zoom: 0.8 }}
          connectionLineType={ConnectionLineType.SmoothStep}
          nodeTypes={nodeTypes}
          nodesConnectable={false}
          onNodeDragStop={handleNodeDragStop}
          // panOnDrag={nodeButtonGroupId === null}
          // fitView={true}
        >
          <MiniMap />
          <Controls />
        </ReactFlow>
      </div>
    </div>
  );
};
export default () => (
  <ReactFlowProvider>
    <ArchitectureGraph />
  </ReactFlowProvider>
);
