import React from 'react';
import { Button, Input, Label } from 'reactstrap';
import RightArrow from '../../../../../../../images/right-arrow.svg';
import { protocolsMeta } from '../../../../../../../utils/constants';
import Select from '../../../../../../UI/SelectRequired';

const ForwardingRules = ({
  loadBalancerEntity,
  changeLoadBalancerEntityHandler,
  changeForwardingHandler,
  addForwardingRuleHandler,
}) => {
  return (
    <div className="architecture-form-block w-100 rounded bg-white mt-2">
      <div className="bg-light-blue">
        <div className="architecture-form-block-header p-3">
          <p className="font-size-lg bold mb-0">Inbound Rules</p>
        </div>
      </div>
      {loadBalancerEntity?.forwardingRules?.length > 0 &&
        loadBalancerEntity.forwardingRules.map((rule, index) => (
          <div key={index} className="px-3 pt-3">
            <div className="architecture-form-shadow p-3 position-relative">
              <button
                className="trash-button-right btn btn-sm btn-danger px-2"
                onClick={() => {
                  const newRules = [...loadBalancerEntity.forwardingRules];
                  newRules.splice(index, 1);
                  changeLoadBalancerEntityHandler('forwardingRules', newRules);
                }}
              >
                <i className="fas fa-trash" />
              </button>
              <div className="d-flex mt-2">
                <div className="d-flex w-50">
                  <div className="onoffswitch">
                    <input
                      type="checkbox"
                      className="onoffswitch-checkbox"
                      id="sslAutogenerate"
                      name="ssl-autogenerate"
                      checked={rule.sslAutogenerate}
                      onChange={() => changeForwardingHandler(index, 'sslAutogenerate', !rule.sslAutogenerate)}
                      data-cy="architecture-ssl-autogenerate"
                    />
                    <label
                      className="onoffswitch-label mb-0"
                      htmlFor="sslAutogenerate"
                      data-cy="architecture-ssl-autogenerate-label"
                    >
                      <span className="onoffswitch-inner" />
                      <span className="onoffswitch-switch" />
                    </label>
                  </div>
                  <span className="bold ml-2">SSL Generate</span>
                </div>
                <div className="d-flex w-50">
                  <div className="onoffswitch">
                    <input
                      type="checkbox"
                      className="onoffswitch-checkbox"
                      id="sslBackend"
                      name="ssl-backend"
                      checked={rule.sslBackend}
                      onChange={() => changeForwardingHandler(index, 'sslBackend', !rule.sslBackend)}
                      data-cy="architecture-ssl-backend"
                    />
                    <label
                      className="onoffswitch-label mb-0"
                      htmlFor="sslBackend"
                      data-cy="architecture-ssl-backend-label"
                    >
                      <span className="onoffswitch-inner" />
                      <span className="onoffswitch-switch" />
                    </label>
                  </div>
                  <span className="bold ml-2">Use https on backend</span>
                </div>
              </div>
              <div className="d-flex mt-2">
                <div className="w-50">
                  <Label for={`forwardingRule-${index}-sslCertificate`} className="ml-1 bold">
                    SSL certificate
                  </Label>
                  <Input
                    id={`forwardingRule-${index}-sslCertificate`}
                    data-cy={`forwardingRule-${index}-sslCertificate`}
                    type="text"
                    className="form-control"
                    value={rule.sslCertificate}
                    onChange={e => changeForwardingHandler(index, 'sslCertificate', e.target.value)}
                    placeholder="sslCertificate"
                  />
                </div>
                <div className="w-50 mx-1">
                  <Label for={`forwardingRule-${index}-url`} className="ml-1 bold">
                    URL
                  </Label>
                  <Input
                    id={`forwardingRule-${index}-url`}
                    data-cy={`forwardingRule-${index}-url`}
                    type="text"
                    className="form-control"
                    value={rule.url}
                    onChange={e => changeForwardingHandler(index, 'url', e.target.value)}
                    placeholder="URL"
                  />
                </div>
                <div className="w-50">
                  <Label for={`forwardingRule-${index}-indexFilePath`} className="ml-1 bold">
                    Index file path
                  </Label>
                  <Input
                    id={`forwardingRule-${index}-indexFilePath`}
                    data-cy={`forwardingRule-${index}-indexFilePath`}
                    type="text"
                    className="form-control"
                    value={rule.indexFilePath}
                    onChange={e => changeForwardingHandler(index, 'indexFilePath', e.target.value)}
                    placeholder="indexFilePath"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      <Button className="btn btn-sm btn-success ml-3 my-3" onClick={addForwardingRuleHandler}>
        Add Rule
      </Button>
    </div>
  );
};

export default ForwardingRules;
