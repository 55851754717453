import React, { useContext, useEffect, useState } from 'react';
import { HeaderContext } from '../../contexts/HeaderContext';
import ArchitectureGraph from './component/ArchitectureGraph';
import ArchitectureProvider from './context/ArchitectureContext';
import { ArchitectureContext } from './context/ArchitectureContext';
import EntityForms from './component/EntityForms';
import ModalClosingEntity from './component/modals/ModalClosingEntity';
import ArchitectureCode from './component/ArcitectureCode';
import { Button } from 'reactstrap';
import SummaryBlock from './component/summary/SummaryBlock';
import ModalDeletingEntity from './component/modals/ModalDeletingEntity';
import ModalTransferEntity from './component/modals/ModalTransferEntity';
import ModalEntityLogs from './component/modals/ModalEntityLogs';
import ModalReviewAndDeploy from './component/modals/ModalReviewAndDeploy';

const ArchitectureDetails = () => {
  const { architectureDetails, setIsSummaryOpen, setNodeAddModalId, setNodeButtonGroupId } = useContext(
    ArchitectureContext
  );
  const { setHeader } = useContext(HeaderContext);
  const [codePreview, setCodePreview] = useState(false);

  useEffect(() => {
    if (architectureDetails) {
      setHeader(architectureDetails.name);
    }
  }, [architectureDetails]);

  const summaryClickHandler = () => {
    setIsSummaryOpen(true);
    setNodeAddModalId(null);
    setNodeButtonGroupId(null);
  };

  return (
    <div className="d-flex flex-column w-100">
      <div className="architecture-sticky-header">
        <div className="d-flex align-items-center bg-gray px-2 rounded">
          <div className="architecture-code-on-off">
            <div className="onoffswitch">
              <input
                type="checkbox"
                className="onoffswitch-checkbox"
                id="enableCode"
                name="enable-code"
                checked={codePreview}
                onChange={e => setCodePreview(!codePreview)}
                data-cy="architecture-enable-code"
              />
              <label className="onoffswitch-label mb-0" htmlFor="enableCode" data-cy="architecture-enable-code-label">
                <span className="onoffswitch-inner" />
                <span className="onoffswitch-switch" />
              </label>
            </div>
          </div>
          <span className="ml-2">{codePreview ? 'View Graph' : 'Code'}</span>
        </div>
        {!codePreview && (
          <Button className="btn btn-sm btn-violet btn-secondary" onClick={summaryClickHandler}>
            Summary & deploy
          </Button>
        )}
      </div>
      {codePreview ? (
        <ArchitectureCode className="arcitecture-code-edition" />
      ) : (
        <>
          <ArchitectureGraph />
          <EntityForms />
          <ModalClosingEntity />
          <ModalDeletingEntity />
          <ModalTransferEntity />
          <ModalEntityLogs />
          <SummaryBlock />
          <ModalReviewAndDeploy />
        </>
      )}
    </div>
  );
};

export default ({ match }) => (
  <ArchitectureProvider>
    <ArchitectureDetails />
  </ArchitectureProvider>
);
