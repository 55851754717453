export default {
  org: {
    trial_end: new Date(Date.now() + 12096e5),
    allow_trial_creation: false,
    tenantname: '',
    address: '',
    city: '',
    country: '',
    postcode: '',
    supportemail: '',
    financeemail: [],
    phone: '',
    type: '2',
    trial: false,
    billingplan: '1',
    '2factor': false,
  },
  billingTypes: [
    {
      value: '2',
      label: 'Pre-paid',
    },
    {
      value: '1',
      label: 'Post-paid',
    },
  ],
  availableTypes: canCreateResellers =>
    canCreateResellers
      ? [
          {
            value: '1',
            label: 'Reseller',
          },
          {
            value: '2',
            label: 'End customer',
          },
        ]
      : [
          {
            value: '2',
            label: 'End customer',
          },
        ],
};

export const addOptions = [
  { label: 'Select Existing', value: 'existing' },
  { label: 'New SSH Key', value: 'new' },
];
