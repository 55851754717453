import React, { useState, useContext } from 'react';
import { Handle, Position } from 'reactflow';
import { Popover, Button, PopoverBody } from 'reactstrap';

import InternetSvg from '../../../../images/internet.svg';
import ServerSvg from '../../../../images/server-icon.svg';
import FirewallSvg from '../../../../images/firewall.svg';
import LoadBalancerSvg from '../../../../images/load_balancer.svg';
import WanSvg from '../../../../images/cloudPilot/wan-icon.svg';
import LanSvg from '../../../../images/cloudPilot/lan-icon.svg';
import AddComponents from './AddComponents';
import { ArchitectureContext } from '../context/ArchitectureContext';
import clsx from 'clsx';

const iconMap = {
  internet: InternetSvg,
  device: ServerSvg,
  firewall: FirewallSvg,
  loadBalancer: LoadBalancerSvg,
  wan: WanSvg,
  lan: LanSvg,
};

export const GraphNode = ({ data, isConnectable }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);

  const {
    architectureDetails,
    setIsEntityOpen,
    setIsSummaryOpen,
    setEntityData,
    setIsDeleteModalOpen,
    setIsTransferModalOpen,
    nodeButtonGroupId,
    setNodeButtonGroupId,
    nodeAddModalId,
    setNodeAddModalId,
    setIsEntityLogsModalOpen,
  } = useContext(ArchitectureContext);

  const nodeClickHandler = () => {
    setIsSummaryOpen(false);
    setNodeAddModalId(null);
    setNodeButtonGroupId(null);
    setIsEntityOpen(true);
    setEntityData(data);
  };

  const deleteClickHandler = () => {
    setIsEntityOpen(false);
    setEntityData(data);
    setNodeButtonGroupId(null);
    setNodeAddModalId('');
    setIsDeleteModalOpen(true);
  };

  const transferClickHandler = () => {
    setIsEntityOpen(false);
    setEntityData(data);
    setNodeButtonGroupId(null);
    setNodeAddModalId('');
    setIsTransferModalOpen(true);
  };

  const openEntityLogsHandler = () => {
    setIsEntityOpen(false);
    setEntityData(data);
    setNodeButtonGroupId(null);
    setNodeAddModalId('');
    setIsTransferModalOpen(false);
    setIsEntityLogsModalOpen(true);
  };

  return (
    <div
      className="graph-node position-relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {data.type !== 'internet' && (
        <div>
          <div className="d-flex justify-content-between align-items-center py-1 position-absolute pt-2 px-1 w-100">
            <div>
              {data.status === 'not ready' && (
                <>
                  <i
                    id={'not-ready-info' + data.id}
                    className="fas fa-exclamation-circle font-red cursor-pointer ml-1"
                  />
                  <Popover
                    placement="top"
                    isOpen={popoverIsOpen}
                    trigger="hover"
                    target={'not-ready-info' + data.id}
                    toggle={() => setPopoverIsOpen(!popoverIsOpen)}
                  >
                    <PopoverBody>This {data.type} is not ready for deployment</PopoverBody>
                  </Popover>
                </>
              )}
            </div>
            <div className="position-relative">
              <Button
                id={'button-group' + data.id}
                color="btn btn-sm btn-white node-button-group"
                onClick={() => {
                  setNodeAddModalId('');
                  setNodeButtonGroupId(data.id);
                }}
              >
                <i className="fas fa-ellipsis-v" />
              </Button>
              {nodeButtonGroupId === data.id && (
                <div className="position-fixed options-popover">
                  <div className="d-flex flex-column align-items-start px-3 py-2">
                    <Button color="d-flex py-1 grayOnHover w-100" onClick={transferClickHandler}>
                      <div className="d-flex align-items-center">
                        <i className="fas fa-exchange-alt mr-2" />
                        <p className="font-size-lg mb-0">Transfer</p>
                      </div>
                    </Button>
                    <Button color="d-flex py-1 grayOnHover w-100" onClick={openEntityLogsHandler}>
                      <div className="d-flex align-items-center">
                        <i className="far fa-list-alt mr-2" />
                        <p className="font-size-lg mb-0">Logs</p>
                      </div>
                    </Button>
                    <Button color="d-flex py-1 grayOnHover w-100 text-danger" onClick={deleteClickHandler}>
                      <div className="d-flex align-items-center">
                        <i className="fas fa-trash mr-2" />
                        <p className="font-size-lg mb-0">Delete</p>
                      </div>
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
      <div className="graph-image-node bg-white" onClick={nodeClickHandler}>
        <img
          className={clsx('mb-2', ['wan', 'lan'].includes(data.type) ? 'width-px-40' : 'width-px-50')}
          src={iconMap[data.type]}
          alt="node-icon"
        />
        <div className="graph-node-label text-center bold">{data.name}</div>
      </div>
      {data.type === 'internet' && (
        <div className="architecture-cloud width-px-200">
          <span className="bold ml-2 mb-2">{architectureDetails.cloudName}</span>
        </div>
      )}
      <span className="graph-add-component">
        <i
          id={'add-' + data.id}
          onClick={() => {
            setNodeButtonGroupId(null);
            setNodeAddModalId(data.id);
          }}
          className="fas fa-plus"
        />
        {nodeAddModalId === data.id && (
          <div className="position-fixed add-nodes-popover p-4">
            <AddComponents component={data} closePopover={() => setNodeAddModalId('')} />
          </div>
        )}
      </span>

      <Handle type="source" position={Position.Bottom} isConnectable={isConnectable} />
    </div>
  );
};

export default GraphNode;
