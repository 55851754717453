import React, { useContext, useEffect, useState } from 'react';
import axios from '../../../../../../../axiosInstance';
import { AuthContext } from '../../../../../../contexts/AuthContext';
import LinuxIcon from '../../../../../../../images/cloudPilot/linux-icon-blue.svg';
import WindowsIcon from '../../../../../../../images/cloudPilot/windows-icon-blue.svg';
import FirewallIcon from '../../../../../../../images/cloudPilot/firewall-icon-blue.svg';
import CustomIcon from '../../../../../../../images/cloudPilot/custom-icon-blue.svg';
import IsoIcon from '../../../../../../../images/cloudPilot/iso-icon-blue.svg';
import Select from 'react-select';
import { cloudId } from '../../../../constants';
import { FormFeedback, Input, Label } from 'reactstrap';

const NamingAndOs = ({ deviceEntity, changeDeviceEntityHandler, setTemplateInfo }) => {
  const [templates, setTemplates] = useState({});
  const [selectedType, setSelectedType] = useState('templates_linux');
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [options, setOptions] = useState([]);

  const { tenant } = useContext(AuthContext);

  const osTypes = [
    { label: 'Linux', value: 'templates_linux', icon: LinuxIcon },
    { label: 'Windows', value: 'templates_windows', icon: WindowsIcon },
    { label: 'Firewall', value: 'templates_firewalls', icon: FirewallIcon },
    { label: 'Custom', value: 'templates_custom', icon: CustomIcon },
    { label: "Custom ISO's", value: 'custom_isos', icon: IsoIcon },
  ];

  useEffect(() => {
    if (!tenant) return;

    const getTemplates = async () => {
      const res = await axios.get(`/api/user/device/templates?tenant=${tenant}&cloudId=${cloudId}`);

      if (!res?.data) return;

      setTemplates(res.data);

      if (!deviceEntity.templateId) {
        setSelectedType('templates_linux');
        setSelectedEntity(null);
        return;
      }

      const osTypeValues = osTypes.map(item => item.value);

      for (const type of Object.keys(res.data)) {
        if (!osTypeValues.includes(type)) continue;

        const foundTemplate = res.data[type]?.find(template => template.id === deviceEntity.templateId);
        if (foundTemplate) {
          setSelectedType(type);
          setSelectedEntity({
            label: foundTemplate.name,
            value: foundTemplate.id,
            template: foundTemplate,
          });
          break;
        }
      }
    };

    getTemplates();
  }, [tenant, deviceEntity.templateId]);

  const getTemplateDetails = template => {
    if (template?.id) {
      const { category, type } = template;
      const id = category === 'iso' ? template.iso_id : template.id;

      axios
        .get(`/api/user/${tenant}/vmlist/create/${category}/${type}/${id}`)
        .then(response => {
          if (response?.data) {
            setTemplateInfo(response.data);
          }
        })
        .catch(error => {
          console.warn(error.message);
        });
    }
  };

  // useEffect(() => {
  //   getTemplateDetails(templates[selectedType].template);
  // }, [selectedEntity]);

  useEffect(() => {
    if (templates[selectedType]) {
      setOptions(
        templates[selectedType].map(template => ({
          label: template.name,
          value: template.id,
          template,
        }))
      );
      getTemplateDetails(templates[selectedType][0]);
    }
  }, [selectedType, templates]);

  const selectedOsType = osTypes.find(type => type.value === selectedType);

  return (
    <div className="architecture-form-block flex w-100 rounded bg-white">
      <div className="architecture-form-block-header p-3">
        <p className="font-size-lg bold mb-0">Naming and OS</p>
      </div>
      <div className="p-4">
        <div className="architecture-form-shadow">
          <div className="d-flex align-items-center bold p-2 bg-soft-blue">
            {selectedOsType && (
              <img width="28" src={selectedOsType.icon} alt={`${selectedOsType.label}-icon`} className="mr-1" />
            )}
            <Select
              id="osType"
              className="selector-no-border w-100"
              options={osTypes}
              onChange={e => {
                setSelectedEntity(null);
                setSelectedType(e.value);
              }}
              value={selectedOsType || null}
            />
          </div>
          <Select
            className="m-2"
            options={options}
            onChange={e => {
              changeDeviceEntityHandler('templateId', e.value);
              setSelectedEntity(e);
              getTemplateDetails(e.template);
            }}
            value={selectedEntity}
          />
        </div>
        <FormFeedback className="d-block input__danger-message">{deviceEntity.errors.first('templateId')}</FormFeedback>
        <div className="row mt-3">
          <div className="col-6 pr-2">
            <Label for="displayname" className="bold">
              Display name
            </Label>
            <Input
              id="displayname"
              data-cy="displayname"
              className="w-100"
              type="text"
              placeholder="Display name"
              value={deviceEntity.name || ''}
              onChange={e => changeDeviceEntityHandler('name', e.target.value)}
            />
            <FormFeedback className="d-block input__danger-message">{deviceEntity.errors.first('name')}</FormFeedback>
          </div>
          <div className="col-6 pl-2">
            <Label for="hostname" className="bold">
              Host name
            </Label>
            <Input
              id="hostname"
              data-cy="hostname"
              className="w-100"
              type="text"
              placeholder="Host name"
              value={deviceEntity.hostname || ''}
              onChange={e => changeDeviceEntityHandler('hostname', e.target.value)}
            />
            <FormFeedback className="d-block input__danger-message">
              {deviceEntity.errors.first('hostname')}
            </FormFeedback>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NamingAndOs;
