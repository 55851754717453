import React, { useContext, useEffect, useState } from 'react';
import MemoryIcon from '../../../../../../../images/cloudPilot/memory-small-icon.svg';
import CpuIcon from '../../../../../../../images/cloudPilot/cpu-small-icon.svg';
import DiskIcon from '../../../../../../../images/cloudPilot/disk-small-icon.svg';
import SwapIcon from '../../../../../../../images/cloudPilot/swap-small-icon.svg';
import { v4 as uuidv4 } from 'uuid';
import { PushNotificationContext } from '../../../../../../contexts/PushNotificationContext';
import cloneObject from '../../../../../../../utils/cloneObject';
import { object } from 'prop-types';
import cpu from '../../../../../../../images/Config/cpu';
import { FormFeedback } from 'reactstrap';

const Hardware = ({ deviceEntity, changeDeviceEntityHandler, templateInfo }) => {
  const [ardwareLimits, setardwareLimits] = useState({
    diskSize: { min: 1, max: 1000, gap: 5 },
    memory: { min: 1, max: 100, gap: 1 },
    cpuCoreCount: { min: 1, max: 16, gap: 1 },
    swapDiskSize: { min: 1, max: 1000, gap: 1 },
  });

  useEffect(() => {
    if (templateInfo && templateInfo.template) {
      setardwareLimits(prevLimits => ({
        ...prevLimits,
        diskSize: { ...prevLimits.diskSize, min: templateInfo.template.hdd_minsize_gb },
      }));

      if (deviceEntity.diskSize < templateInfo.template.hdd_minsize_gb) {
        changeDeviceEntityHandler('diskSize', templateInfo.template.hdd_minsize_gb);
      }

      if (deviceEntity.memory < templateInfo.template.ram_minsize_gb) {
        changeDeviceEntityHandler('memory', templateInfo.template.ram_minsize_gb);
      }

      if (deviceEntity.cpuCoreCount < templateInfo.template.cpu_mincount) {
        changeDeviceEntityHandler('cpuCoreCount', templateInfo.template.cpu_mincount);
      }

      if (deviceEntity.swapDiskSize < templateInfo.template.swap_minsize_gb) {
        changeDeviceEntityHandler('swapDiskSize', templateInfo.template.swap_minsize_gb);
      }
    }
  }, [templateInfo]);

  const { pushNotification } = useContext(PushNotificationContext);

  const changeValueHandler = (key, value) => {
    const minValue = ardwareLimits[key].min;
    const maxValue = ardwareLimits[key].max;

    if (value < minValue) {
      pushNotification({
        data: {
          type: 'danger',
          name: `You cannot set a value less than ${minValue}`,
        },
        id: uuidv4(),
      });
      return;
    }

    if (value > maxValue) {
      pushNotification({
        data: {
          type: 'danger',
          name: `You cannot set a value more than ${maxValue}`,
        },
        id: uuidv4(),
      });
      return;
    }

    changeDeviceEntityHandler(key, value);
  };

  return (
    <div className="architecture-form-block w-100 rounded bg-white my-4">
      <div className="architecture-form-block-header p-3">
        <p className="font-size-lg bold mb-0">Hardware</p>
      </div>
      <div className="row mx-1 my-4 pb-2 px-1">
        <div className="col-6 px-2 mb-3">
          <div className="architecture-form-shadow rounded d-flex justify-content-between align-items-center bg-white height-px-40">
            <div className="d-flex">
              <img className="height-px-20 pl-3 pr-2 width-px-50" src={DiskIcon} alt="hardware-icon" />
              Disk, Gb
            </div>
            <div className="d-flex width-px-70 justify-content-center align-items-center text-blue-dark">
              <button
                className="btn-nostyle bold pr-2"
                onClick={() => changeValueHandler('diskSize', deviceEntity.diskSize - 1)}
              >
                <span className="font-size-lg">-</span>
              </button>
              <span className="font-size-md">{deviceEntity.diskSize}</span>
              <button
                className="btn-nostyle bold pl-2 pr-3"
                onClick={() => changeValueHandler('diskSize', deviceEntity.diskSize + 1)}
              >
                <span className="font-size-lg">+</span>
              </button>
            </div>
          </div>
          <FormFeedback className="d-block input__danger-message">{deviceEntity.errors.first('diskSize')}</FormFeedback>
        </div>
        {templateInfo.template?.hdd_minsize_gb && (
          <div className="col-6 px-2 mb-3">
            <div className="architecture-form-shadow rounded d-flex justify-content-between align-items-center bg-white height-px-40">
              <div className="d-flex">
                <img className="height-px-20 pl-3 pr-2 width-px-50" src={MemoryIcon} alt="hardware-icon" />
                RAM, Gb
              </div>
              <div className="d-flex width-px-70 justify-content-center align-items-center text-blue-dark">
                <button
                  className="btn-nostyle bold pr-2"
                  onClick={() => changeValueHandler('memory', deviceEntity.memory - 1)}
                >
                  <span className="font-size-lg">-</span>
                </button>
                <span className="font-size-md">{deviceEntity.memory}</span>
                <button
                  className="btn-nostyle bold pl-2 pr-3"
                  onClick={() => changeValueHandler('memory', deviceEntity.memory + 1)}
                >
                  <span className="font-size-lg">+</span>
                </button>
              </div>
            </div>
            <FormFeedback className="d-block input__danger-message">{deviceEntity.errors.first('memory')}</FormFeedback>
          </div>
        )}
        <div className="col-6 px-2 mb-3">
          <div className="architecture-form-shadow rounded d-flex justify-content-between align-items-center bg-white height-px-40">
            <div className="d-flex">
              <img className="height-px-20 pl-3 pr-2 width-px-50" src={CpuIcon} alt="hardware-icon" />
              CPU, Cores
            </div>
            <div className="d-flex width-px-70 justify-content-center align-items-center text-blue-dark">
              <button
                className="btn-nostyle bold pr-2"
                onClick={() => changeValueHandler('cpuCoreCount', deviceEntity.cpuCoreCount - 1)}
              >
                <span className="font-size-lg">-</span>
              </button>
              <span className="font-size-md">{deviceEntity.cpuCoreCount}</span>
              <button
                className="btn-nostyle bold pl-2 pr-3"
                onClick={() => changeValueHandler('cpuCoreCount', deviceEntity.cpuCoreCount + 1)}
              >
                <span className="font-size-lg">+</span>
              </button>
            </div>
          </div>
          <FormFeedback className="d-block input__danger-message">
            {deviceEntity.errors.first('cpuCoreCount')}
          </FormFeedback>
        </div>
        {templateInfo.template?.has_swap === 1 && templateInfo.template?.allowed_setdisk === 1 && (
          <div className="col-6 px-2 mb-3">
            <div className="architecture-form-shadow rounded d-flex justify-content-between align-items-center bg-white height-px-40">
              <div className="d-flex">
                <img className="height-px-20 pl-3 pr-2 width-px-50" src={SwapIcon} alt="hardware-icon" />
                SWAP, Gb
              </div>
              <div className="d-flex width-px-70 justify-content-center align-items-center text-blue-dark">
                <button
                  className="btn-nostyle bold pr-2"
                  onClick={() => changeValueHandler('swapDiskSize', deviceEntity.swapDiskSize - 1)}
                >
                  <span className="font-size-lg">-</span>
                </button>
                <span className="font-size-md">{deviceEntity.swapDiskSize}</span>
                <button
                  className="btn-nostyle bold pl-2 pr-3"
                  onClick={() => changeValueHandler('swapDiskSize', deviceEntity.swapDiskSize + 1)}
                >
                  <span className="font-size-lg">+</span>
                </button>
              </div>
            </div>
            <FormFeedback className="d-block input__danger-message">
              {deviceEntity.errors.first('swapDiskSize')}
            </FormFeedback>
          </div>
        )}
      </div>
    </div>
  );
};

export default Hardware;
