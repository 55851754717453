import React, { useContext, useEffect, useState } from 'react';
import SelectedEntity from './formComponents/SelectedEntity';
import NamingAndOs from './formComponents/Device/NamingAndOs';
import Hardware from './formComponents/Device/Hardware';
import Security from './formComponents/Device/Security';
import EntityKeyInput from './formComponents/EntityKeyInput';
import ForwardingRules from './formComponents/LoadBalancer/ForwardingRules';
import OutboundRules from './formComponents/Firewall/OutboundRules';
import { Button } from 'reactstrap';
import { ArchitectureContext } from '../../context/ArchitectureContext';
import { AuthContext } from '../../../../contexts/AuthContext';
import { useParams } from 'react-router-dom';
import axios from '../../../../../axiosInstance';
import Connections from './formComponents/Connections';

const FirewallForm = () => {
  const [loadBalancerEntity, setLoadBalancerEntity] = useState({});
  const [isLoadBalancerEntityReady, setIsLoadBalancerEntityReady] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const { entityData, setIsEntityChanged, fetchDetails, setIsEntityOpen } = useContext(ArchitectureContext);
  const { tenant } = useContext(AuthContext);
  const { id } = useParams();

  useEffect(() => {
    if (entityData) {
      setLoadBalancerEntity(entityData.data);
      setIsLoadBalancerEntityReady(true);
    }
  }, [entityData]);

  console.log(loadBalancerEntity);

  const changeLoadBalancerEntityHandler = (key, value) => {
    setLoadBalancerEntity({
      ...loadBalancerEntity,
      [key]: value,
    });
  };

  const changeForwardingHandler = (index, key, value) => {
    const newRules = [...loadBalancerEntity.forwardingRules];
    newRules[index][key] = value;
    changeLoadBalancerEntityHandler('forwardingRules', newRules);
  };

  const addForwardingRuleHandler = () => {
    const newRules = [...loadBalancerEntity.forwardingRules];
    newRules.push({
      sslAutogenerate: true,
      sslBackend: true,
      sslCertificate: '',
      url: '',
      maintenanceMode: 0,
      indexFilePath: '',
    });
    changeLoadBalancerEntityHandler('forwardingRules', newRules);
  };

  const updateScriptHandler = async () => {
    setIsUpdating(true);

    axios
      .put(`/api/user/${tenant}/architectures/${id}/node/${entityData.id}`, {
        type: 'loadBalancer',
        data: loadBalancerEntity,
      })
      .then(res => {
        setIsUpdating(false);
        setIsEntityChanged(false);
        setIsEntityOpen(false);
        fetchDetails();
      })
      .catch(e => {
        setIsUpdating(false);
        console.warn(e.message);
      });
  };

  return (
    <div className="d-flex flex-column full-height">
      {isLoadBalancerEntityReady ? (
        <div>
          <SelectedEntity />
          <Connections />
          <EntityKeyInput
            type="Load Balancer"
            inputKey="name"
            entity={loadBalancerEntity}
            changeEntityHandler={changeLoadBalancerEntityHandler}
          />
          <ForwardingRules
            loadBalancerEntity={loadBalancerEntity}
            changeLoadBalancerEntityHandler={changeLoadBalancerEntityHandler}
            changeForwardingHandler={changeForwardingHandler}
            addForwardingRuleHandler={addForwardingRuleHandler}
          />
        </div>
      ) : (
        <span>Loading...</span>
      )}
      <div className="architecture-sticky-button-wrapper mt-3">
        <Button className="btn-violet w-100" onClick={() => updateScriptHandler()} disabled={isUpdating}>
          {isUpdating && (
            <span className="spinner-border spinner-border-sm mb-1 mr-2" role="status" aria-hidden="true" />
          )}
          Update script
        </Button>
      </div>
    </div>
  );
};

export default FirewallForm;
